import { getNameLabel } from "./utils"

const LiveTrackingMobileComponent = (props: any) => {
    return <div className='live-tracking-mobile-component'>
        {props.selectedRacers.map((racer: any) => {
            return <div key={racer.bib} className='name-circle'>
                <span>{getNameLabel(racer.name)}</span>
                <img alt='delete' onClick={()=> props.deleteSelectedRacer(racer.id)} style={{ position: 'absolute', top: -5, right: -5, cursor:'pointer' }} src='assets/delete.svg' />
            </div>
        })}
    </div>
}
export default LiveTrackingMobileComponent


