

const Header = ()=>{
    return <div className='header'>
        <h2 className='title'>RACEMATE REPLAY</h2>
        <div className='logo'>
            <img alt='pyramids' height={60} src ='assets/pyramids.png'/>
            <img alt='logo' height={60} style={{marginLeft:50}} src ='assets/racemate.png'/>

        </div>
    </div>
}
export default Header