import { getNameLabel } from "./utils"

const LiveTrackingDesktopComponent = (props: any) => {
    return <div className='live-tracking-desktop-component'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3 style={{ margin: 0 }}>WATCHING</h3>
            <span onClick={()=> props.clearAllSelectedRacers()} style={{ color: '#E02D2D', fontSize: 12, fontWeight: 'bold', cursor:'pointer' }}>CLEAR ALL</span>
        </div>
        <div style={{ paddingLeft: '10px' }}>
            {props.selectedRacers.map((racer: any) => {
                return <div key={racer.id} className='card' style={{ display: 'flex', justifyContent: 'space-between', marginTop: 21 , alignItems:'center'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ position: 'relative' }}>
                            <img alt='marker' src='/assets/map_marker.png' width={30} />
                            <span style={{ position: 'absolute', left: 5, top: 5, color: '#fff', fontWeight: 'bold', fontSize: 14 }}>{getNameLabel(racer.name)}</span>
                        </div>
                        <div style={{ marginLeft: 15 }}>
                            <span>{racer.bib}</span>
                            <h3 style={{ margin: 0 }}>{racer.name}</h3>
                        </div>
                    </div>

                    <span onClick={()=> props.deleteSelectedRacer(racer.id)} style={{cursor:'pointer'}}><img alt='remove' src='/assets/remove.svg' /></span>
                </div>
            })}

        </div>
    </div>
}
export default LiveTrackingDesktopComponent