import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { Autocomplete, Button, Slider, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Header from './header';
import './App.css'
import { getNameLabel } from './utils';
import { PATH_DATA_5K } from './constants/5k';
import { PATH_DATA_10K } from './constants/10k';
import { PATH_DATA_21K } from './constants/21k';
import LiveTrackingDesktopComponent from './liveTrackingComponent';
import LiveTrackingMobileComponent from './liveTrackingMobileComponent';


const Timer = ({ setTimerFn, startTime }: { setTimerFn: Function, startTime: number }) => {
  const [time, setTime] = useState(startTime);
  useEffect(() => {
    setTimerFn(setTime);
  }, [setTimerFn])
  return <div className='timer'>
    <span className='title'>RACE TIME </span>
    <h2>{new Date(time * 1000).toLocaleTimeString([], { hour12: false })} </h2>
  </div>
}

const MyComponent = () => {
  const [markers, setMarkers] = useState<any>([])
  const [activeMarker, setActiveMarker] = useState<any>(null)
  const [raceDistance, setRaceDistance] = useState<string>("5")
  const pathData5k = PATH_DATA_5K
  const pathData10k = PATH_DATA_10K
  const pathData21k = PATH_DATA_21K
  const pathData = raceDistance === "5" ? pathData5k : raceDistance === "10" ? pathData10k : pathData21k
  const [data, setData] = useState<any>(null)

  const [isActive, setIsActive] = useState(false);
  const [speed, setSpeed] = useState<number>(1)
  const startTimestamp = 1639202550
  const endTimestamp = 1639213200
  const timestamp = useRef(startTimestamp)
  const setTime = useRef<any>([])
  const [selectedRacers, setSelectedRacers] = useState([])

  const setTimerFn = (fn: React.Dispatch<React.SetStateAction<number>>) => {
    setTime.current.push(fn);
  };

  const getLocationsInfo = () => {
    let filteredDataIds = selectedRacers.map((x: any) => x.id)
    let newFilteredData = selectedRacers.length === 0 ? data : Object.fromEntries(Object.entries(data).filter(([key, value]) => filteredDataIds.includes(key)))
    let locationData = selectedRacers.length === 0 ? Object.values(newFilteredData) : selectedRacers
    const locations = locationData.map((user: any) => {
      let value = user.data.find((obj: any) => obj.rawTime > timestamp.current)
      if (value === undefined) {
        value = user.data[user.data.length - 1]
        // setIsActive(false)
      }
      return { lat: value.lat, lon: value.lon, userId: user.id, userName: user.name }
    })
    return locations
  }

  useEffect(() => {
    if (raceDistance === '5') {
      let data = require('./data/data5k.json')
      setData(data)
    }
    else if (raceDistance === '10') {
      let data = require('./data/data10k.json')
      setData(data)
    }
    else if (raceDistance === '21') {
      let data = require('./data/data21k.json')
      setData(data)
    }

  }, [raceDistance])


  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (isActive) {
      interval = setInterval(() => {
        timestamp.current += speed
        setTime.current.forEach((fn: Function) => {
          fn(timestamp.current);
        });
        if (timestamp.current > endTimestamp){
          clearInterval(interval!);
          setIsActive(false)
          return
        }
        ///// setIsactive = false
        getLocationsInfo().forEach((loc: any, i: number) => {
          markers.markers[i].setPosition(new google.maps.LatLng(loc.lat, loc.lon));
          markers.markers[i].set('id', loc.userId)
          markers.markers[i].set('label', getNameLabel(loc.userName))
        })
        markers.repaint();
      }, 100)
    } else if (!isActive) {
      clearInterval(interval);
    }
    return () => clearInterval(interval!);
  }, [isActive, speed, data, selectedRacers]);

  useEffect(() => {
    if (data && markers.markers) {
      getLocationsInfo().forEach((loc: any, i: number) => {
        markers.markers[i].setPosition(new google.maps.LatLng(loc.lat, loc.lon));
        markers.markers[i].set('id', loc.userId)
        markers.markers[i].set('label', getNameLabel(loc.userName))
      })
    }

  }, [selectedRacers, data, markers])




  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCqsoDWGPzPWq5EwEIwbXR1kJ_wppzDbNk"
  })

  const onMarkerLoad = (markers: any) => {
    setMarkers(markers)
  }

  const deleteSelectedRacer = (racerId: string) => {
    let newSelectedRacers = [...selectedRacers.filter((racer: any) => racer.id !== racerId)]
    if (newSelectedRacers.length === 0) {
      setSelectedRacers([])
    }
    else {
      setSelectedRacers(newSelectedRacers)
    }
  }
  const clearAllSelectedRacers = () => {
    setSelectedRacers([])
  }

  const changeRaceDistance = (newDistance: string) => {
    if (newDistance) {
      setRaceDistance(newDistance)
      setSelectedRacers([])
    }
  }

  const pathOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 300000,
    zIndex: 1
  };

  const mapContainerStyle = {
    width: '100%',
    height: '100%'
  };

  const options = {
    minimumClusterSize: 10,
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  const path = pathData.map((e: any) => {
    return { lat: e.lat, lng: e.lon }
  })


  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(14)
    map.setCenter({
      "lat": PATH_DATA_10K[0].lat,
      "lng": PATH_DATA_10K[0].lon
    })
  }, [])

  function toggle() {
    setIsActive(!isActive);
  }

  return data === null ? <div>loading....</div> : <div style={{ height: '100%' }}>
    <div className='desktop'>
      <Header />
    </div >
    {isLoaded ? (
      <div className='content-wrapper'>
        <div className='map-filters'>
          <div className='controllers-1-desktop'>
            <Timer startTime={startTimestamp} setTimerFn={setTimerFn} />
            <div className='speed'>
              <span className='label'>REPLAY SPEED</span>
              <Slider defaultValue={1} aria-label="Default" valueLabelDisplay="auto" max={10} style={{ width: 140, marginLeft: 15, color: '#07345D' }} min={1}
                onChange={(_, newValue) => setSpeed(newValue as number)} />
            </div>
            <Button variant="outlined" className={isActive ? 'button-stop' : 'button-start'} onClick={toggle}>
              {isActive ? 'Stop' : 'Start'}
            </Button>
          </div>
          <div className='controllers-2'>
            <ToggleButtonGroup
              color="primary"
              value={raceDistance}
              exclusive
              onChange={(_, newDistance) => changeRaceDistance(newDistance)}
            >
              <ToggleButton value="5">5k</ToggleButton>
              <ToggleButton value="10">10k</ToggleButton>
              <ToggleButton value="21">21k</ToggleButton>
            </ToggleButtonGroup>
            <div className='search-input'>
              <img alt='logo' className='mobile' src='/assets/logo.svg' style={{ position: 'absolute', left: 15, top: 10 }} />
              <img alt='search' src='/assets/search.svg' style={{ position: 'absolute', right: 15, top: 10 }} />

              <Autocomplete
                
                forcePopupIcon={false}
                placeholder='Search by Name, Bib'
                disableClearable
                options={Object.values(data).map((x: any) => x)}
                renderInput={(params) => {
                  return <TextField style={{color:'red'}}  {...params} />
                }}
                onChange={(event, newValue) => {
                  let racers: any = new Set(selectedRacers)
                  racers.add(newValue)
                  setSelectedRacers(Array.from(racers));
                }}
                getOptionLabel={(option: any) => option.bib + ' ' + option.name}
                value={{ name: 'Search by Name, Bib', bib: '' }}
                isOptionEqualToValue={(option: any, value: any) => option.bib === value.bib}
                getOptionDisabled={(option) => option.bib === ''}
              />
            </div>
          </div>
        </div>
        <div className='desktop'>
          <LiveTrackingDesktopComponent
            selectedRacers={selectedRacers}
            deleteSelectedRacer={(racerId: string) => deleteSelectedRacer(racerId)}
            clearAllSelectedRacers={() => clearAllSelectedRacers()}
          />
        </div>
        <div className='mobile'>
          <LiveTrackingMobileComponent
            selectedRacers={selectedRacers}
            deleteSelectedRacer={(racerId: string) => deleteSelectedRacer(racerId)} />
        </div>

        <div className='controllers-1-mobile mobile'>
          <Button variant="outlined" className={(isActive ? 'stop' : 'start') + ' button'} onClick={toggle}>
            {isActive ? <img alt='stop' src='/assets/stop.svg' /> : <img alt='play' src='/assets/play.svg' />}
          </Button>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40, alignItems: 'center', padding: '0 15px' }}>
            <Timer startTime={startTimestamp} setTimerFn={setTimerFn} />
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
              <span className='label' style={{ fontSize: 10 }}>REPLAY SPEED</span>
              <Slider defaultValue={1} aria-label="Default" valueLabelDisplay="auto" max={10} style={{ width: 150, marginTop: 5, color: '#07345D' }} min={1}
                onChange={(_, newValue) => setSpeed(newValue as number)} />
            </div>
          </div>
        </div>
        <GoogleMap id='marker-map' mapContainerStyle={mapContainerStyle}
          onLoad={onLoad}
        >
          <MarkerClusterer options={options} onLoad={onMarkerLoad}>
            {(clusterer) =>
              getLocationsInfo().map((item: any, i: number) => (
                <Marker key={i} position={{ lat: item.lat, lng: item.lon }} clusterer={clusterer} label={getNameLabel(item.userName)}
                  onMouseOver={() => setActiveMarker(item.userId)} onMouseOut={() => setActiveMarker(null)} />
              ))
            }
          </MarkerClusterer>
          {activeMarker !== null && markers && <InfoWindow
            anchor={markers.markers.find((m: any) => m.id === activeMarker)}
          >
            <div>
              {data[activeMarker].name}
            </div>
          </InfoWindow>}
          {path && <Polyline
            path={path}
            options={pathOptions}
          />}
        </GoogleMap>
      </div>
    ) : <></>}
  </div>
}

export default React.memo(MyComponent)
